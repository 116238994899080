<template>
  <div class="page">
    <div class="business infos">
      <div class="scroll-view_H items" style="width: 100%">
        <span class="txt" v-for="(item,index) in businessArray" :key="index">{{item}}</span>
      </div>
      <div class="store">
        <div class="garage-name">{{garageName}}</div>
      </div>
      <div class="pic-box">
        <img class="img img1" src="@/assets/images/after-sales/pic1.png"/>
        <div class="pic-pad">
          <img class="img img2" src="@/assets/images/after-sales/pic2.png"/>
        </div>
        <div class="pic-pad">
          <img class="img img3" src="@/assets/images/after-sales/pic3.png"/>
        </div>
      </div>
      <div class="bottom-tips">*本店对上述内容有最终解释权</div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      businessArray: [],
      garageName: ''
    }
  },
  mounted () {
    document.title='超长质保 放心养车'
    const businessList = this.$route.query['businessList'] || ''
    const garageName = this.$route.query['garageName'] || ''
    this.businessArray = businessList.split(',')
    this.garageName = garageName
  },
  methods: {

  }
}
</script>
<style lang="scss" scoped>
@import "~styles/index.scss";
.page {
  background: #F6F4F4;
}

.business {
  width: px2rem(750px);
}
.scroll-view_H{
  overflow: auto;
  white-space: nowrap;
  padding-bottom: px2rem(44px);
}
.infos .items {
  text-align: center;
  background: #fff;
  padding: px2rem(56px) px2rem(30px) px2rem(44px);
  font-size: px2rem(34px);
  font-weight: bold;
  width: px2rem(690px);
  box-sizing: border-box;
  white-space: nowrap;
}
.infos .items .txt {
  display: inline-block;
  padding: 0 px2rem(20px);
  position: relative;
  font-size:  px2rem(28px);
}

.infos .items .txt::after {
  position: absolute;
  content: '|';
  top: 0;
  right: px2rem(1px);
  font-size: px2rem(28px);
  width: px2rem(2px);
  color: #FD3133;
  display: inline;
  vertical-align: middle;
}
.infos .items .txt:last-child::after{
  content: '';
}

.store {
  position: relative;
  width: px2rem(750px);
  height: px2rem(198px);
  background: url(~images/after-sales/store.png) no-repeat;
  background-size: 100% 100%;
}



.garage-name {
  padding-left: px2rem(70px);
  width: px2rem(416px);
  height: px2rem(45px);
  font-size: px2rem(32px);
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  line-height: px2rem(63px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  z-index: 5;
}

.pic-box .img {
  width: px2rem(750px);
}

.img1 {
  height: px2rem(1461px);
}

.pic-pad {
  width: px2rem(750px);
  padding: px2rem(32px) px2rem(24px) 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}

.img2 {
  width: px2rem(702px);
  height: px2rem(1146px);
}

.img3 {
  width: px2rem(702px);
  height: px2rem(886px);
}

.bottom-tips {
  font-size: px2rem(24px);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: px2rem(33px);
  text-align: center;
  padding: px2rem(24px) 0 px2rem(72px);
}
</style>
